import { SVGAttributes } from 'react';

const HintIcon = ({
  color = 'currentColor',
  height = 20,
  width = 20,
  ...props
}: {
  color?: string;
  height?: number;
  width?: number;
} & SVGAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 20 20"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={10} cy={10} r={9.5} stroke={color} />
    <path
      d="M8.926 14V7.724h2.148V14H8.926zm1.068-6.876c-.672 0-1.176-.468-1.176-1.104 0-.636.504-1.116 1.176-1.116.672 0 1.188.48 1.188 1.116 0 .636-.516 1.104-1.188 1.104z"
      fill={color}
    />
  </svg>
);

export default HintIcon;
