const theme = {
  fontFamily: {
    main: 'Graphik, sans-serif',
    body: 'Graphik, sans-serif',
    serif: "'Libre Casion Text', serif",
  },
  colors: {
    // Gray / Grey
    gray50: '#F1F5F5',
    gray100: '#E6EDED',
    gray200: '#E2E9E9',
    gray300: '#DBE1E1',
    gray400: '#BCC8C8',
    gray500: '#97AAAA',
    gray600: '#789191',
    gray700: '#5A6D6D',
    gray800: '#394646',
    gray900: '#1E2424',

    // White and Black
    white: '#ffffff',
    black: '#121212',

    // Wreno Yellow
    wrenoYellowLightShade: '#F7FFDF',
    wrenoYellowPrimary: '#DFFE7F',
    wrenoYellowDarkShade: '#353B25',

    // Alerts
    alertRed: '#D01111',
    alertRedShade: '#FAE7E7',

    passGreen: '#2C8744',
    passOk: '#0071BC',

    // Machine Green
    machineGreen100: '#B6CACA',
    machineGreen200: '#91AFB0',
    machineGreen300: '#6D9595',
    machineGreen400: '#487A7B',
    machineGreen500: '#3F6E6F',
    machineGreen600: '#376263',
    machineGreen700: '#2B4B4B',
    machineGreen800: '#203737',

    // Cobalt Blue
    cobaltBlue50: '#D2E1EA',
    cobaltBlue100: '#99BBCF',
    cobaltBlue200: '#6699B7',
    cobaltBlue300: '#33779F',
    cobaltBlue400: '#136290',
    cobaltBlue500: '#0B4D79',
    cobaltBlue600: '#0C446A',
    cobaltBlue700: '#043455',
    cobaltBlue800: '#002842',

    // Terracotta
    terracotta50: '#F9F0ED',
    terracotta100: '#E8C5B8',
    terracotta200: '#DDA895',
    terracotta300: '#D18B71',
    terracotta400: '#C66E4E',
    terracotta500: '#B05D3E',
    terracotta600: '#914D33',
    terracotta700: '#633423',
    terracotta800: '#4A271A',
  },
  breakpointsRaw: {
    medium: 640,
    large: 1024,
    xlarge: 1200,
  },
  constants: {
    containerMaxWidth: '1320px',
  },
  breakpoints: {
    medium: `(min-width: 640px)`,
    large: `(min-width: 1024px)`,
    xlarge: `(min-width: 1200px)`,
  },
  variables: {
    headerHeight: 84,
    headerHeightMobile: 62,
    footerHeight: 87,
    footerHeightMobile: 216,
    announcementBarHeight: 45,
    announcementBarHeightMobile: 79,
    maxWidth: 1440,
  },
} as const;

export type ThemeType = typeof theme;
export type ThemeColor = keyof ThemeType['colors'];

export default theme;
