// eslint-disable-next-line import/no-namespace, import/no-extraneous-dependencies
import * as Sentry from '@sentry/browser';
import { NAVIGATION_ABORTED_ERROR } from 'common/constants/errors';
import canUseDOM from './canUseDOM';

if (canUseDOM && process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    ignoreErrors: [NAVIGATION_ABORTED_ERROR],
  });
}
