import type { HTMLAttributes, PropsWithChildren } from 'react';

import {
  TypographyBase,
  TypographyVariant,
  TypographyColorVariant,
} from './Typography.styles';

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  /**
   * The tag in which the typography text will be displayed.
   */
  tagName?: keyof React.JSX.IntrinsicElements;
  /**
   * Typography variant. Changes font size, line height, font weight.
   */
  variant?: TypographyVariant;
  /**
   * Typography color.
   */
  color?: TypographyColorVariant;
}

const Typography = ({
  children,
  tagName,
  variant,
  color,
  ...props
}: PropsWithChildren<TypographyProps>) => {
  return (
    <TypographyBase
      as={tagName || 'div'}
      $variant={variant}
      $color={color}
      {...props}
    >
      {children}
    </TypographyBase>
  );
};

export default Typography;
