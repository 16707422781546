import { CSSProperties, forwardRef } from 'react';
import HintIcon from '../icons/HintIcon';
import { ErrorWrapper, ErrorText } from './Error.styles';
import theme from '../../themes/theme';

export interface ErrorProps {
  errorMessage: string | React.ReactNode;
  className?: string;
  inline?: boolean;
  'data-testid'?: string;
  style?: CSSProperties;
  fill?: boolean;
}

const Error = forwardRef<HTMLDivElement, ErrorProps>(
  (
    {
      errorMessage,
      className,
      style,
      fill,
      inline,
      'data-testid': testId,
    }: ErrorProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ): React.ReactNode => {
    return (
      <ErrorWrapper
        data-testid={testId}
        ref={ref}
        className={`Wreno__Error--container ${className || ''}`}
        $inline={inline}
        $fill={fill}
        style={style}
      >
        <HintIcon color={theme.colors.alertRed} />
        <ErrorText variant={'ctaStandard'}>{errorMessage}</ErrorText>
      </ErrorWrapper>
    );
  },
);

Error.displayName = 'Error';

export default Error;
