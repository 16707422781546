import styled, { css } from 'styled-components';
import { ThemeType } from '../../themes/theme';

export type TypographyVariant =
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'bodyLarge'
  | 'bodyStandard'
  | 'bodySmall'
  | 'ctaStandard'
  | 'ctaSmall'
  | 'eyebrowLarge'
  | 'eyebrowSmall'
  | 'labelLarge'
  | 'labelStandard'
  | 'labelSmall'
  | 'tableEmphasis'
  | 'tableValue';

export type TypographyColorVariant =
  | keyof ThemeType['colors']
  | 'currentColor'
  | 'inherit';

export type TypographyFamilyVariant = keyof ThemeType['fontFamily'];

export type TypographyWeightVariant = 300 | 400 | 500 | 600 | 700 | 800;

export interface TypographyBaseProps {
  $variant?: TypographyVariant;
  $color?: TypographyColorVariant;
}

export const Heading1 = css`
  font-family: ${({ theme }) => theme.fontFamily.main};
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.25px;
  @media ${({ theme }) => theme.breakpoints.large} {
    font-size: 60px;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -1.5px;
  }
`;

export const Heading2 = css`
  font-family: ${({ theme }) => theme.fontFamily.main};
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  @media ${({ theme }) => theme.breakpoints.large} {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -1px;
  }
`;

export const Heading3 = css`
  font-family: ${({ theme }) => theme.fontFamily.main};
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  @media ${({ theme }) => theme.breakpoints.large} {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -1px;
  }
`;

export const Heading4 = css`
  font-family: ${({ theme }) => theme.fontFamily.main};
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  @media ${({ theme }) => theme.breakpoints.large} {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
  }
`;

export const Heading5 = css`
  font-family: ${({ theme }) => theme.fontFamily.main};
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`;

export const Heading6 = css`
  font-family: ${({ theme }) => theme.fontFamily.main};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const BodyLarge = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  @media ${({ theme }) => theme.breakpoints.large} {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }
`;

export const BodyStandard = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  @media ${({ theme }) => theme.breakpoints.large} {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const BodySmall = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0;
  @media ${({ theme }) => theme.breakpoints.large} {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
`;

export const CTAStandard = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const CTASmall = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`;

export const EyebrowLarge = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.112px;
  @media ${({ theme }) => theme.breakpoints.large} {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
  }
`;

export const EyebrowSmall = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.112px;
`;

export const LabelLarge = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  @media ${({ theme }) => theme.breakpoints.large} {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const LabelStandard = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

export const TableValue = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

export const TableEmphasis = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
`;

export const LabelSmall = css`
  font-family: ${({ theme }) => theme.fontFamily.body};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  @media ${({ theme }) => theme.breakpoints.large} {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
  }
`;

type FontVariantStyle = ReturnType<typeof css>;

function getFontVariant(variant: TypographyVariant, styles: FontVariantStyle) {
  return (props: TypographyBaseProps) => {
    if (props.$variant === variant) {
      return styles;
    }
    return '';
  };
}

export const TypographyBase = styled.div<TypographyBaseProps>`
  margin: 0;
  padding: 0;
  ${getFontVariant('heading1', Heading1)};
  ${getFontVariant('heading2', Heading2)};
  ${getFontVariant('heading3', Heading3)};
  ${getFontVariant('heading4', Heading4)};
  ${getFontVariant('heading5', Heading5)};
  ${getFontVariant('heading6', Heading6)};
  ${getFontVariant('bodyLarge', BodyLarge)};
  ${getFontVariant('bodyStandard', BodyStandard)};
  ${getFontVariant('bodySmall', BodySmall)};
  ${getFontVariant('ctaStandard', CTAStandard)};
  ${getFontVariant('ctaSmall', CTASmall)};
  ${getFontVariant('eyebrowLarge', EyebrowLarge)};
  ${getFontVariant('eyebrowSmall', EyebrowSmall)};
  ${getFontVariant('labelLarge', LabelLarge)};
  ${getFontVariant('labelStandard', LabelStandard)};
  ${getFontVariant('labelSmall', LabelSmall)};
  ${getFontVariant('tableValue', TableValue)};
  ${getFontVariant('tableEmphasis', TableEmphasis)};
  color: ${(props) => getAppropriateColor(props.$color, props.theme)};
`;

function getAppropriateColor(
  color: TypographyColorVariant | undefined,
  theme: ThemeType,
): string {
  if (color && color in theme.colors) {
    return theme.colors[color as keyof ThemeType['colors']];
  } else if (color) {
    return color;
  }
  return theme.colors.black;
}
