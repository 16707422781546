import styled, { keyframes } from 'styled-components';
import { LoaderColorVariant } from './Loader';
import { ThemeType } from '../../themes/theme';

const animation = keyframes`
  0% {
    transform: perspective(150px) rotateX(0deg);
  }
  100% {
    transform: perspective(150px) rotateX(180deg);
  }
`;

function getSize(size?: string | number) {
  if (size && typeof size === 'number') return `${size}px`;
  if (size) return size;
  return '40px';
}

function getAppropriateColor(
  color: LoaderColorVariant | undefined,
  theme: ThemeType,
): string | undefined {
  if (color && color in theme.colors) {
    return theme.colors[color as keyof ThemeType['colors']];
  } else if (color) {
    return color;
  }
}

export const Parent = styled.div<{
  $color?: LoaderColorVariant;
  $size?: string | number;
}>`
  width: ${({ $size }) => getSize($size)};
  aspect-ratio: 1;
  position: relative;
  background: ${({ $color, theme }) =>
    getAppropriateColor($color, theme) || theme.colors.black};
  animation: ${animation} 1s infinite;
`;

export const Container = styled.div<{
  $backgroundColor?: LoaderColorVariant;
  $fullscreen?: boolean;
}>`
  background: ${({ $backgroundColor, theme }) =>
    getAppropriateColor($backgroundColor, theme) || 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;

  ${({ $fullscreen }) =>
    $fullscreen &&
    `
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  `}
`;
