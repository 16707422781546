import styled from 'styled-components';
import Typography from '../Typography';

interface ErrorWrapperProps {
  $inline?: boolean;
  $fill?: boolean;
}

export const ErrorWrapper = styled.div<ErrorWrapperProps>`
  width: 100%;
  background: ${({ theme }) => theme.colors.alertRedShade};
  color: ${({ theme }) => theme.colors.alertRed};
  display: flex;
  align-items: flex-start;
  padding: 20px;
  border-radius: 8px;
  box-sizing: border-box;
  ${({ $inline }) =>
    $inline &&
    `
    background: transparent;
    padding: 8px 0 0 6px;
  `}
  ${({ $fill }) =>
    $fill &&
    `
    height: 100%;
    align-items: center;
    justify-content: center;
  `}
  svg {
    flex-shrink: 0;
  }
`;

export const ErrorText = styled(Typography)`
  margin-top: -3px;
  margin-left: 8px;
  color: ${(props) => props.theme.colors.alertRed};
`;
