import { Parent, Container } from './Loader.styles';
import { ThemeType } from '../../themes/theme';

interface LoaderProps {
  // Style overrides
  className?: string;
  // Force disable loader. Useful for always rendering loader and conditionally enabling
  disabled?: boolean;
  // Should take over full app with loader
  fullscreen?: boolean;
  // Should render loader inline
  inline?: boolean;
  // Loader color override
  color?: LoaderColorVariant;
  // Background color override
  backgroundColor?: LoaderColorVariant;
  // The size the loader should be
  size?: number | string;
}

export type LoaderColorVariant =
  | keyof ThemeType['colors']
  | 'currentColor'
  | 'inherit';

const RootLoader = ({ className, size, color }: LoaderProps) => (
  <Parent
    $size={size}
    $color={color}
    data-testid="loader"
    className={className}
  />
);
function Loader(props: LoaderProps) {
  if (props.disabled) {
    return null;
  }
  if (props.inline) {
    return <RootLoader {...props} />;
  }
  return (
    <Container
      $backgroundColor={props.backgroundColor}
      $fullscreen={props.fullscreen}
      className={props.className}
    >
      <RootLoader {...props} className={undefined} />
    </Container>
  );
}

export default Loader;
