import styled from 'styled-components';
import { Component, ErrorInfo, ReactNode } from 'react';
import { captureException } from '@sentry/nextjs';
import BaseError from 'styleguide/src/components/Error';
import Button from 'styleguide/src/components/Button';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

const Error = styled(BaseError)`
  height: 100vh;
  a,
  button {
    color: ${({ theme }) => theme.colors.alertRed};
  }
`;

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line
    console.error(error, errorInfo);
    captureException(error);
  }

  public onResolve() {
    window.location.reload();
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Error
          fill
          errorMessage={
            <>
              <p>Looks like we ran into a snag.</p>
              <p>
                If you think this is a problem with us,{' '}
                <a color="primary" href="mailto:vendorsupport@wreno.io">
                  please tell us
                </a>
                .
              </p>

              <Button onClick={this.onResolve} variant="tertiary">
                Reload
              </Button>
            </>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
