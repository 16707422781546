import styled, { css } from 'styled-components';
import _Loader from '../Loader';
import { CTAStandard } from '../Typography/Typography.styles';
import theme from '../../themes/theme';

export const buttonVariants = [
  'primary',
  'secondary',
  'tertiary',
  'primary-yellow',
  'secondary-yellow',
] as const;

export type ButtonColors = 'primary' | 'secondary' | 'alert';

type ButtonTheme = {
  [key in ButtonColors]: {
    secondary: string;
    primary: string;
  };
};

const buttonTheme: ButtonTheme = {
  primary: {
    secondary: theme.colors.wrenoYellowPrimary,
    primary: theme.colors.black,
  },
  secondary: {
    secondary: theme.colors.wrenoYellowPrimary,
    primary: theme.colors.gray800,
  },
  alert: {
    secondary: theme.colors.white,
    primary: theme.colors.alertRed,
  },
};

const ButtonPrimary = (color: ButtonColors, disabled?: boolean) => css`
  background-color: ${(props) =>
    disabled ? props.theme.colors.gray200 : buttonTheme[color].primary};
  color: ${(props) =>
    disabled ? props.theme.colors.gray600 : buttonTheme[color].secondary};
  border: ${(props) =>
    disabled ? `solid 1px ${props.theme.colors.gray500}` : 'none'};
`;

const ButtonSecondary = (color: ButtonColors, disabled?: boolean) => css`
  background: transparent;
  border: 1px solid
    ${(props) =>
      disabled ? props.theme.colors.gray500 : buttonTheme[color].primary};
  color: ${(props) =>
    disabled ? props.theme.colors.gray500 : buttonTheme[color].primary};
`;

const ButtonTertiary = (color: ButtonColors, disabled?: boolean) => css`
  background: transparent;
  border: none;
  height: 20px;
  padding: 0;
  color: ${(props) =>
    disabled ? props.theme.colors.gray500 : buttonTheme[color].primary};
`;

const ButtonPrimaryYellow = (disabled?: boolean) => css`
  background-color: ${(props) =>
    disabled
      ? props.theme.colors.gray200
      : props.theme.colors.wrenoYellowPrimary};
  color: ${(props) =>
    disabled ? props.theme.colors.gray600 : props.theme.colors.black};
  border: ${(props) =>
    disabled ? `solid 1px ${props.theme.colors.gray500}` : 'none'};
`;

const ButtonSecondaryYellow = (disabled?: boolean) => css`
  background: transparent;
  border: 1px solid
    ${(props) =>
      disabled
        ? props.theme.colors.gray500
        : props.theme.colors.wrenoYellowPrimary};
  color: ${(props) =>
    disabled
      ? props.theme.colors.gray500
      : props.theme.colors.wrenoYellowPrimary};
`;

export type ButtonVariants = (typeof buttonVariants)[number];

interface ButtonLabelProps {
  $offsetLeft?: boolean;
  $offsetRight?: boolean;
}

export const ButtonStyled = styled.button<{
  disabled?: boolean;
  $variant: ButtonVariants;
  $color: ButtonColors;
  $width?: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: ${(props) => (props.$width ? `${props.$width}px` : 'auto')};
  border-radius: 20px;
  padding: 8px 25px;
  cursor: pointer;
  ${CTAStandard};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  transition: all 0.2s ease;
  text-decoration: none;
  box-sizing: border-box;
  max-height: 40px;
  &:focus-visible {
    outline-offset: 4px;
  }
  &:hover {
    opacity: 0.75;
  }

  ${({ $variant, $color, disabled }) => {
    switch ($variant) {
      case 'primary':
        return ButtonPrimary($color, disabled);
      case 'secondary':
        return ButtonSecondary($color, disabled);
      case 'tertiary':
        return ButtonTertiary($color, disabled);
      case 'primary-yellow':
        return ButtonPrimaryYellow(disabled);
      case 'secondary-yellow':
        return ButtonSecondaryYellow(disabled);
    }
  }}
`;

export const ButtonLabel = styled.div<ButtonLabelProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.$offsetLeft ? '8px' : 'initial')};
  margin-right: ${(props) => (props.$offsetRight ? '8px' : 'initial')};
`;

export const Loader = styled(_Loader)`
  margin: 0 12px;
`;
